<template>
    <div class="PurchaseInDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form :id="formId" ref="form" label-width="80px" width="auto" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="单据号">
                                <el-input v-model="form.code" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="状态">
                                <el-input :value="form.stockStatus | stockInStatus" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="配送中心">
                                <el-input v-model="form.repoName" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="采购机构">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input v-model="form.creator" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="操作人">
                                <el-input v-model="form.updater" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="操作时间">
                                <el-input v-model="form.updateTime" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.detailParams"
                size="mini"
                show-summary
                max-height="450"
                :id="tableId"
            >
                <el-table-column label="序号" type="index" width="50" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column label="条码" width="120">
                    <template slot-scope="scope">
                        <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column label="采购价" width="100" prop="showPrices" v-if="showInPrice()">
                    <template slot-scope="scope">
                        {{ scope.row.showPrices }}
                    </template>
                </el-table-column>
                <template v-if="deptEnabledBigUnit">
                    <el-table-column label="包装因子" width="100" prop="packFactors">
                        <template slot-scope="scope">
                            {{ scope.row.packFactors }}
                        </template>
                    </el-table-column>
                    <el-table-column label="采购箱数" prop="bigUnitCounts" width="100" />
                    <el-table-column label="散装数量" prop="bulkCounts" width="100" />
                </template>
                <el-table-column label="采购总数" width="100" prop="counts" />
                <el-table-column label="采购金额" width="120" prop="purchaseShowMoney" v-if="showInPrice()" />
                <el-table-column label="赠品数量" width="80" prop="giftCounts" />
                <el-table-column label="生产日期" width="140" prop="produceDates" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import { get } from 'request/http';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import { endLoading, startLoading } from 'js/Loading';

export default {
    name: 'PurchaseInDetail',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            deptEnabledBigUnit: false,
            form: {
                code: '',
                stockStatus: null,
                createTime: '',
                creator: '',
                updater: '',
                updateTime: '',
                deptName: '',
                repoName: '',
                deptCode: '',
                repoCode: '',
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            baseUrl: '/purchase/in',
            //id标识
            formId: 'form',
            tableId: 'table',
        };
    },
    mounted() {
        (async () => {
            startLoading();
            //查询数据
            const rst = await get(`${this.baseUrl}/info_c/${this.code}`);
            Util.copyProperties(rst, this.form);
            //是否启用大单位权限
            this.deptEnabledBigUnit = await this.$efApi.deptPrivilegeApi.hasDeptPrivilege(
                this.form.deptCode,
                'dept.biz.goods.allow.bigUnit'
            );
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                rst,
                this.detailParamPropertyArrForShow()
            );
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
            endLoading();
        })();
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        detailParamPropertyArrForShow() {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCounts',
                'produceDates',
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
            if (this.deptEnabledBigUnit) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        //已保存的数据中的包装因子直接展示，不考虑配送中心是否开启大单位权限
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return Util.isEmpty(bulkCounts) ? detailParam.counts % detailParam.packFactors : bulkCounts;
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'purchaseShowMoney', (purchaseShowMoney, detailParam) => {
                    return this.decimal(detailParam.counts)
                        .mul(detailParam.showPrices || 0)
                        .toNumber();
                })
            );
            return rst;
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
