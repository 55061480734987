export default {
    SkuTypeEnum: {
        STANDARD: 0,
        NOT_STANDARD: 1,
        properties: {
            0: { desc: '标品', precision: 0 },
            1: { desc: '非标品', precision: 3 },
        },
    },
    skuTypeDesc(type) {
        const property = this.SkuTypeEnum.properties[type] || {};
        return property.desc || '';
    },

    GoodsStatusEnum: {
        DOWN_SHELF: 0,
        UP_SHELF: 1,
        BANNED: 2,
        properties: {
            0: { desc: '已下架' },
            1: { desc: '已上架' },
            2: { desc: '禁售' },
        },
    },
    goodsStatusDesc(status) {
        const property = this.GoodsStatusEnum.properties[status] || {};
        return property.desc || '';
    },
};
