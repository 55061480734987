import goodsApi from 'request/api/goodsApi';
import Util from 'js/Util';
import GoodsCommon from 'js/GoodsCommon';

export const goodsStockProperty = 'stock';
export const factoryPrice = 'factoryPrice';
export const wholeSalePrice = 'wholeSalePrice';
export const retailPrice = 'retailPrice';
export const planCostPrice = 'planCostPrice';
export const packFactor = 'packFactor';
export const wmaCostPrice = 'wmaCostPrice';

export class PropertyConverter {
    constructor(sourceProperty, targetProperty, handleSourceValFunc) {
        this.sourceProperty = sourceProperty;
        this.targetProperty = targetProperty;
        this.handleSourceValFunc = handleSourceValFunc;
    }
}

export default {
    AdjustAbstractTypeEnum: {
        LOSS: 1,
        OVERFLOW: 2,
        properties: {
            1: { desc: '报损', type: 1 },
            2: { desc: '报溢', type: 2 },
        },
    },
    allAbstractType2Desc() {
        return [
            this.AdjustAbstractTypeEnum.properties[this.AdjustAbstractTypeEnum.LOSS],
            this.AdjustAbstractTypeEnum.properties[this.AdjustAbstractTypeEnum.OVERFLOW],
        ];
    },
    abstractTypeDesc(type) {
        return (
            this.allAbstractType2Desc().find((e) => {
                return e.type === type;
            }).desc || ''
        );
    },
    StockBizStatusEnum: {
        NEED_CHANGE: 0,
        CHANGED: 1,
        properties: {
            0: { desc: '待出库', status: 0 },
            1: { desc: '已出库', status: 1 },
        },
    },
    StockBizStatusEnum_enter: {
        NEED_CHANGE: 0,
        CHANGED: 1,
        properties: {
            0: { desc: '待入库', status: 0 },
            1: { desc: '已入库', status: 1 },
        },
    },
    allStockBizStatus2Desc() {
        return [
            this.StockBizStatusEnum.properties[this.StockBizStatusEnum.NEED_CHANGE],
            this.StockBizStatusEnum.properties[this.StockBizStatusEnum.CHANGED],
        ];
    },
    allStockBizStatus2Desc_enter() {
        return [
            this.StockBizStatusEnum_enter.properties[this.StockBizStatusEnum.NEED_CHANGE],
            this.StockBizStatusEnum_enter.properties[this.StockBizStatusEnum.CHANGED],
        ];
    },
    /**
     * 校验单据是否能够编辑
     * @param bizReviewStatus
     * @returns {boolean}
     */
    validateCanEdit(bizReviewStatus) {
        if (Util.isEmpty(bizReviewStatus)) {
            return false;
        }
        return bizReviewStatus === 0;
    },
    /**
     * 校验单据能否被删除
     * @param bizReviewStatus
     * @returns {boolean}
     */
    validateCanDelete(bizReviewStatus) {
        if (Util.isEmpty(bizReviewStatus)) {
            return false;
        }
        return bizReviewStatus === 0;
    },
    /**
     * 校验单据是否能够审核
     * @param bizReviewStatus
     * @returns {boolean}
     */
    validateCanReview(bizReviewStatus) {
        if (Util.isEmpty(bizReviewStatus)) {
            return false;
        }
        return bizReviewStatus === 0;
    },
    /**
     * 校验是否能够出库/入库
     * @param bizReviewStatus
     * @param stockStatus
     * @returns {boolean}
     */
    validateCanChangeStock(bizReviewStatus, stockStatus) {
        if (Util.isEmpty(bizReviewStatus) || Util.isEmpty(stockStatus)) {
            return false;
        }
        return bizReviewStatus === 2 && stockStatus === 0;
    },
    /**
     * 根据库存单据详情并查询相关商品数据，最终返回单据中的商品列表数据和单据详情列表，源字段与目标字段一致
     * @param bizDetailRst
     * @param detailParamPropertyArrForShow
     * @returns {Promise<{goodsDetails: Map<String, Object>, detailParams: []}>}
     */
    async makeStockBizDetailGoodsAndParams(bizDetailRst = {}, detailParamPropertyArrForShow = []) {
        if (
            Object.prototype.toString.call(bizDetailRst) !== '[object Object]' ||
            detailParamPropertyArrForShow.length === 0
        ) {
            return { goodsDetails: new Map(), detailParams: [] };
        }

        const goodsCodes = bizDetailRst.goodsCode || bizDetailRst.goodsCodes || [];
        //查询商品信息
        const goodsArr = await goodsApi.goodsListByCodes(goodsCodes);
        const goodsMap = new Map();
        goodsArr.forEach((e) => {
            goodsMap.set(e.code, e);
        });
        //根据detailParamProperty生成详情数据列表
        const detailParams = [];
        goodsCodes.forEach((goodsCode, idx) => {
            //默认初始化时增加商品信息
            const param = { goodsDetail: goodsMap.get(goodsCode) };
            detailParamPropertyArrForShow.forEach((e) => {
                this.handleTargetProperty(
                    (sourceProperty) => {
                        return (bizDetailRst[sourceProperty] || [])[idx];
                    },
                    param,
                    e
                );
            });
            detailParams.push(param);
        });
        return { goodsDetails: goodsMap, detailParams: detailParams };
    },

    /**
     * 根据商品弹框选择的商品列表更新业务单据商品详情(页面数据)
     * @param selectedGoods
     * @param goodsDetailMap
     * @param detailParams
     * @param detailParamPropertyArrForAdd
     */
    addPickupGoodsToStockBizDetails(
        selectedGoods = [],
        goodsDetailMap = new Map(),
        detailParams = [],
        detailParamPropertyArrForAdd = [],
        imp = false
    ) {
        if (detailParamPropertyArrForAdd.length === 0) {
            return;
        }
        const goodsCodePropertyIdx = detailParamPropertyArrForAdd.indexOf('goodsCode');
        if (goodsCodePropertyIdx < 0) {
            console.error('detailParamPropertyArr缺少goodsCode字段');
            return;
        }
        selectedGoods.forEach((goods) => {
            const idx = detailParams.findIndex((param) => {
                return goods.code === param.goodsCode;
            });
            //新选择的数据，增加到头部
            const param = {};
            detailParamPropertyArrForAdd.forEach((e) => {
                let property = e;
                if (e === 'goodsCode') {
                    property = new PropertyConverter('code', 'goodsCode');
                }
                this.handleTargetProperty(
                    (sourceProperty) => {
                        if (Util.isEmpty(sourceProperty)) {
                            //源字段为空，说明不需要从goods中提取数据，设置为null
                            return null;
                        } else {
                            return goods[sourceProperty];
                        }
                    },
                    param,
                    property
                );
            });
            if (imp) {
                if (idx >= 0) {
                    detailParams.splice(idx, 1);
                }
                detailParams.unshift(param);
                goodsDetailMap.set(goods.code, goods);
            } else {
                if (idx === -1) {
                    detailParams.unshift(param);
                    goodsDetailMap.set(goods.code, goods);
                }
            }
        });
    },

    /**
     * 将库存单据详情对象obj转换成后台需要的各个字段数组形式的对象
     * @param detailParams
     * @param detailParamPropertyArrForSubmit
     * @returns {{}}
     */
    convertStockBizDetailParamsToParamArrObj(detailParams = [], detailParamPropertyArrForSubmit = []) {
        if (detailParams.length === 0 || detailParamPropertyArrForSubmit.length === 0) {
            return {};
        }
        const paramArrObj = {};
        detailParamPropertyArrForSubmit.forEach((e) => {
            this.handleTargetProperty(
                (sourceProperty) => {
                    return detailParams.map((param) => {
                        return param[sourceProperty];
                    });
                },
                paramArrObj,
                e
            );
        });
        return paramArrObj;
    },

    handleTargetProperty(sourceValFunc, targetObj, property) {
        let handleSourceValFunc;
        let sourceProperty = property;
        let targetProperty = property;
        if (property instanceof PropertyConverter) {
            handleSourceValFunc = property.handleSourceValFunc;
            sourceProperty = property.sourceProperty;
            targetProperty = property.targetProperty;
        }
        const sourceVal = sourceValFunc(sourceProperty);
        let targetVal;
        if (handleSourceValFunc) {
            //调用处理函数，处理目标属性值
            if (sourceVal instanceof Array) {
                //数组类型，循环处理
                targetVal = sourceVal.map((e) => {
                    //只有单个字段数据
                    return handleSourceValFunc(e);
                });
            } else {
                //字段数据和目标对象
                targetVal = handleSourceValFunc(sourceVal, targetObj);
            }
        } else {
            targetVal = sourceVal;
        }
        //先设置好字段初始值，否则会无法设置值
        targetObj[targetProperty] = null;
        if (targetVal instanceof Promise) {
            //如果targetVal类型为Promise，则需要进行then处理
            targetVal.then((e) => {
                targetObj[targetProperty] = Util.isEmpty(e) ? null : e;
            });
        } else {
            targetObj[targetProperty] = Util.isEmpty(targetVal) ? null : targetVal;
        }
    },

    /**
     * 验证标品商品数量
     * @param count
     * @returns {boolean|*|void}
     */
    validateStandardCount(count) {
        //非负整数（正整数 + 0）
        return Util.regValidateMatch(count, /^\d+$/, true);
    },

    validatePackFactor(packFactor) {
        //非零的正整数
        return Util.regValidateMatch(packFactor, /^\+?[1-9][0-9]*$/, true);
    },

    /**
     * 验证非标品商品数量
     * @param count
     * @returns {boolean|*|void}
     */
    validateNotStandardCount(count) {
        //非负正数，最多${precision}位小数
        const precision = GoodsCommon.SkuTypeEnum.properties[GoodsCommon.SkuTypeEnum.NOT_STANDARD].precision;
        return Util.regValidateMatch(count, new RegExp(`^(([1-9]\\d+)|[0-9])(\\.\\d{1,${precision}})?$`), true);
    },

    validateStockBizCount(rule, count, callback, detailParamsFunc, skuByGoodsCodeFunc) {
        //正则匹配获取数值
        rule.fullField.match(/\.(\d*)\./);
        const idx = Number(RegExp.$1);
        const goodsCode = detailParamsFunc()[idx].goodsCode || detailParamsFunc()[idx].goodsCodes;
        const skuType = skuByGoodsCodeFunc(goodsCode).type;
        switch (skuType) {
            case GoodsCommon.SkuTypeEnum.STANDARD:
                return this.validateStandardCount(count) ? callback() : callback(new Error('请正确填写数量'));
            case GoodsCommon.SkuTypeEnum.NOT_STANDARD:
                return this.validateNotStandardCount(count) ? callback() : callback(new Error('请正确填写数量'));
            default:
                return callback(new Error('数量异常'));
        }
    },
};
